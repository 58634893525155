/* ================ MOSTRAR/ESCONDER MENU ================ */
const navMenu = document.getElementById('nav-menu'),
    navToggle = document.getElementById('nav-toggle')

// valida se a constante existe
if (navToggle) {
    navToggle.addEventListener('click', () => {
        navMenu.classList.toggle('show-menu')
        navToggle.parentElement.classList.toggle('show-menu')
    })
}


/* ================ REMOVER MENU MOBILE ================ */
const navLink = document.querySelectorAll('.nav__link')

function linkAction() {
    navMenu.classList.remove('show-menu')
    navToggle.parentElement.classList.remove('show-menu')
}
navLink.forEach(n => n.addEventListener('click', linkAction))


/* ================ SWIPER ENTENDA ================ */
const swiperEntenda = document.getElementById('entenda-swiper')

if (swiperEntenda) {
    new Swiper(swiperEntenda, {
        spaceBetween: 20,
        navigation: {
            nextEl: '.swiper-button-next-entenda',
            prevEl: '.swiper-button-prev-entenda',
        },
        pagination: {
            el: '.swiper-pagination-entenda',
        },
        breakpoints: {
            992: {
                slidesPerView: 2,
                slidesPerGroup: 2,
            }
        }
    });
}


/* ================ SWIPER EFEITO ESTUFA ================ */
const swiperEstufa = document.getElementById('estufa-swiper')

if (swiperEstufa) {
    new Swiper(swiperEstufa, {
        spaceBetween: 20,
        navigation: {
            nextEl: '.swiper-button-next-estufa',
            prevEl: '.swiper-button-prev-estufa',
        },
        pagination: {
            el: '.swiper-pagination-estufa',
        },
        breakpoints: {
            992: {
                slidesPerView: 2,
                slidesPerGroup: 2,
            }
        }
    });
}


/* ================ SWIPER ADITIVOS ================ */
const swiperAditivos = document.getElementById('aditivos-swiper')

if (swiperAditivos) {
    new Swiper(swiperAditivos, {
        spaceBetween: 20,
        pagination: {
            el: '.swiper-pagination-aditivos',
        },
        navigation: {
            nextEl: '.swiper-button-next-aditivos',
            prevEl: '.swiper-button-prev-aditivos',
        },
        // autoHeight: true,
        breakpoints: {
            1230: {
                slidesPerView: 1,
            }
        }
    });
}


/* ================ SWIPER ONDE ENCONTRAR ================ */
const swiperOndeEncontrar = document.getElementById('ondeencontrar-swiper')

if (swiperOndeEncontrar) {
    new Swiper(swiperOndeEncontrar, {
        spaceBetween: 20,
        slidesPerView: 1.6,
        navigation: {
            nextEl: '.swiper-button-next-ondeencontrar',
            prevEl: '.swiper-button-prev-ondeencontrar',
        },
        breakpoints: {
            992: {
                slidesPerView: 4,
            }
        }
    });
}


/* ================ MÁSCARA DE TELEFONE ================ */
// document.getElementById('phone').addEventListener('input', function (e) {
//     var x = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
//     e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
// });


/* ================ MODAL ONDE ENCONTRAR ================ */
const modalViews = document.querySelectorAll('.box-logo-modal'),
    modalBtns = document.querySelectorAll('.box-logo-btn'),
    modalCloses = document.querySelectorAll('.box-logo-modal-close')

let modal = function (modalClick) {
    modalViews[modalClick].classList.add('active')
}

modalBtns.forEach((modalBtn, i) => {
    modalBtn.addEventListener('click', () => {
        modal(i)
    })
})

modalCloses.forEach((modalClose) => {
    modalClose.addEventListener('click', () => {
        modalViews.forEach((modalView) => {
            modalView.classList.remove('active')
        })
    })
})


/* ================ MODAL TERMOS DE USO ================ */
const modalOpenTermos = document.querySelectorAll('.modal-termos-open'),
    modalCloseTermos = document.getElementById('modal-termos-close'),
    modalTermos = document.getElementById('modal-termos')

modalOpenTermos.forEach((modalOpen) => {
    modalOpen.addEventListener('click', () => {
        modalTermos.classList.add('active')
    })
})

if (modalCloseTermos) {
    modalCloseTermos.addEventListener('click', () => {
        modalTermos.classList.remove('active')
    })
}